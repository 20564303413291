import React from "react"
import styled from "styled-components"
import { MAX_MOBILE_WIDTH } from "../constants/sizes"

const doneTick = require("../images/done.svg")

const FeaturesWrapper = styled.ul<{ maxWidthDesktop: string }>`
  max-width: ${p => p.maxWidthDesktop};
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    max-width: none;
  }

  li {
    background: url("${doneTick}") no-repeat left top;
    padding-left: 50px;

    margin: 0;
    margin-bottom: 1.5em;
    list-style: none;
  }
`

interface IProps {
  features: string[]
  maxWidthDesktop?: string
}
export default function Features({
  features,
  maxWidthDesktop = "none",
}: IProps) {
  return (
    <FeaturesWrapper maxWidthDesktop={maxWidthDesktop}>
      {features.map(feature => (
        <li key={feature}>{feature}</li>
      ))}
    </FeaturesWrapper>
  )
}
