import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Center from "./Center"
import Button from "./Button"

export default function FreeQuoteCTA() {
  const intl = useIntl()

  return (
    <Center>
      <Button
        to={intl.formatMessage({ id: "pages.quote" })}
        className="get-free-quote"
      >
        {intl.formatMessage({ id: "freeQuote" })}
      </Button>
    </Center>
  )
}
