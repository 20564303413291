import React from "react"
import styled from "styled-components"

import { WHITE_BACKGROUND } from "../constants/colors"
import { MAX_MOBILE_WIDTH } from "../constants/sizes"

const UpgradeSecurityWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${WHITE_BACKGROUND};

    padding: 1.5em 1em;
    margin: 0 2em;
    border-radius: 15px;

    @media only screen and (max-width: ${MAX_MOBILE_WIDTH}) {
      margin: 0.5em 0em;
      padding: 0.9em 0.5em;
      width: 75%;
    }
  }

  h5 {
    text-align: center;
    white-space: pre;
    margin-bottom: 0;
  }
`

interface IProps {
  messages: string[]
}
export default function Badges({ messages }: IProps) {
  return (
    <UpgradeSecurityWrapper>
      {messages.map(message => (
        <div key={message}>
          <h5>{message}</h5>
        </div>
      ))}
    </UpgradeSecurityWrapper>
  )
}
