import styled from "styled-components"

import { MAX_MOBILE_WIDTH } from "../constants/sizes"

const Center = styled.div<{ onlyMobile?: boolean }>`
  text-align: ${p => (p.onlyMobile ? "left" : "center")};

  @media only screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    text-align: center;
  }
`
export default Center
