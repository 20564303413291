import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

import Center from "./Center"
import Button from "./Button"

export default function InstallerBanner() {
  const intl = useIntl()

  return (
    <Center>
      <p>
        <FormattedMessage
          id="areYouAnInstaller"
          values={{
            b: (chunks: any) => <b>{chunks}</b>,
          }}
        />
      </p>
      <Button
        to={intl.formatMessage({ id: "pages.installer" })}
        type="secondary"
        className="installer-contact-us"
      >
        {intl.formatMessage({ id: "contactUs" })}
      </Button>
    </Center>
  )
}
