import React, { useState } from "react"
import styled from "styled-components"
import { FormattedMessage } from "gatsby-plugin-intl"
import { DARK_GREY, WHITE_BACKGROUND, HOVER_COLOR } from "../constants/colors"

const TabsWrapper = styled.div`
  display: flex;
  min-width: 75px;
`

const Tab = styled.div<{ selected: boolean }>`
  flex: 1;
  cursor: pointer;
  padding: 1em;
  font-weight: ${p => (p.selected ? "500" : "400")};
  text-align: center;
  :hover {
    background: ${HOVER_COLOR};
  }
`

const TabIndicatorBackground = styled.div`
  height: 5px;
  border-radius: 2px;
  background: ${WHITE_BACKGROUND};
  position: relative;
`

const TabIndicator = styled.div<{ width: string; position: string }>`
  height: 5px;
  width: ${p => p.width};
  border-radius: 2px;
  background: ${DARK_GREY};
  position: absolute;
  transition-duration: 0.3s;
  left: ${p => p.position};
`

interface IProps {
  tabs: string[]
  render: (currentTab: string) => any
}
export default function Tabs({ tabs, render }: IProps) {
  const [currentTab, setCurrentTab] = useState(tabs[0])

  const handleClick = (tab: string) => () => setCurrentTab(tab)

  return (
    <>
      <TabsWrapper>
        {tabs.map(tab => (
          <Tab
            key={tab}
            selected={currentTab === tab}
            onClick={handleClick(tab)}
          >
            <FormattedMessage id={tab} />
          </Tab>
        ))}
      </TabsWrapper>
      <TabIndicatorBackground>
        <TabIndicator
          width={`${100 / tabs.length}%`}
          position={`${
            tabs.findIndex(t => t === currentTab) * (100 / tabs.length)
          }%`}
        />
      </TabIndicatorBackground>
      {render(currentTab)}
    </>
  )
}
