import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

interface IProps {
  text: string
  image: any
}

export default function ImageRequirement({ image, text }: IProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        marginTop: "3em",
        flex: 1,
      }}
    >
      <img src={image} style={{ margin: 0 }} />
      <p>
        <FormattedMessage id={text} />
      </p>
    </div>
  )
}
